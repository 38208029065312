import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import { createBrowserRouter, Navigate, RouteObject, RouterProvider, } from "react-router-dom";
import Root from './routes/root';
import ErrorPage from './error-page';
import { Provider as UrqlProvider } from 'urql';
import { urqlClient } from './lib/provideUrql';
import WithTopbarLayout from 'routes/withTopbar/layout';
import HomePage from 'routes/withTopbar/home';
import AccountLayout from 'routes/withTopbar/account/account-layout';
import AccountProfilePage from 'routes/withTopbar/account/account-profile';
import { ToastContainer } from 'react-toastify';
import { LoginHandler } from 'routes/handlers/login';
import { LoginSuccessHandler } from 'routes/handlers/loginsuccess';
import { ConfirmEmailHandler } from 'routes/handlers/confirmemail';
import { PurchaseMembershipHandler } from 'routes/handlers/purchasemembership';
import AccountEmailsPage from 'routes/withTopbar/account/account-emails';
import AccountMembershipPage from 'routes/withTopbar/account/account-membership';
import AccountLegacyMembershipPage from 'routes/withTopbar/account/account-membership-legacy';
import MembersPage from 'routes/withTopbar/members';
import PaymentCallbackPage from 'routes/withTopbar/paymentcallback';
import ProjectsPage from 'routes/withTopbar/projects';
import UserPage from 'routes/withTopbar/user-id';
import OnboardingPage from 'routes/withTopbar/onboarding';
import MyQrPage from 'routes/withTopbar/myqr';
import AccountDemographicsPage from 'routes/withTopbar/account/account-demographics';
import AdminLayout from 'routes/withTopbar/admin/admin-layout';
import AdminUsersPage from 'routes/withTopbar/admin/admin-users';
import AdminUserPage from 'routes/withTopbar/admin/admin-user';
import AttendancePage from 'routes/withTopbar/attendance';
import AdminProjectsPage from 'routes/withTopbar/admin/admin-projects';
import AdminProjectLayout from 'routes/withTopbar/admin/project/admin-project-layout';
import AdminProjectDescriptionPage from 'routes/withTopbar/admin/project/admin-project-description';
import AdminProjectMembersPage from 'routes/withTopbar/admin/project/admin-project-members';
import AdminProjectAttendanceLayout from 'routes/withTopbar/admin/project/attendance/admin-project-attendance-layout';
import AdminProjectAttendanceTakePage from 'routes/withTopbar/admin/project/attendance/admin-project-attendance-take';
import AttendanceSelfServiceScannerPage from 'routes/attendance-selfscanner';
import AdminProjectAttendanceLiveQrPage from 'routes/admin-project-attendance-liveqr';
import AdminProjectAttendanceAdminscannerPage from 'routes/admin-project-attendance-adminscanner';
import AdminProjectAttendanceHistoryPage from 'routes/withTopbar/admin/project/attendance/admin-project-attendance-history';
import { EcwidSsoHandler } from 'routes/handlers/ecwidsso';
import Temp_GBM_10_3_AdminScannerPage from 'routes/temp_gbm_10_3-adminscanner';

const withTopbarRoutes: RouteObject[] = [
    {
        path: "/",
        element: <HomePage />
    },
    {
        path: "/account",
        element: <AccountLayout />,
        children: [
            {
                index: true,
                element: <Navigate to="/account/profile" replace />
            },
            {
                path: "profile",
                element: <AccountProfilePage />
            },
            {
                path: "emails",
                element: <AccountEmailsPage />
            },
            {
                path: "membership",
                element: <AccountMembershipPage />
            },
            {
                path: "membership/legacy",
                element: <AccountLegacyMembershipPage />
            },
            {
                path: "demographics",
                element: <AccountDemographicsPage />
            }
        ]
    },
    {
        path: "/admin",
        element: <AdminLayout />,
        children: [
            {
                index: true,
                element: <Navigate to="/admin/users" replace /> || {/* TODO Navigate to available page */ }
            },
            {
                path: "users",
                element: <AdminUsersPage />
            },
            {
                path: "users/:userId",
                element: <AdminUserPage />
            },
            {
                path: "projects",
                element: <AdminProjectsPage />
            }
            ,
            {
                path: "projects/:projectId/:projectSessionId",
                element: <AdminProjectLayout />,
                children: [
                    {
                        index: true,
                        element: <AdminProjectDescriptionPage />
                    },
                    {
                        path: "attendance",
                        element: <AdminProjectAttendanceLayout />,
                        children: [
                            {
                                index: true,
                                element: <AdminProjectAttendanceTakePage />
                            },
                            {
                                path: "history",
                                element: <AdminProjectAttendanceHistoryPage />
                            }
                        ]
                    },
                    {
                        path: "members",
                        element: <AdminProjectMembersPage />
                    }
                ]
            }
        ]
    },
    {
        path: "/members",
        element: <MembersPage />
    },
    {
        path: "/projects",
        element: <ProjectsPage />
    }
    ,
    {
        path: "/users/:userId",
        element: <UserPage />
    },
    {
        path: "/paymentcallback",
        element: <PaymentCallbackPage />
    },
    {
        path: "/myqr",
        element: <MyQrPage />
    },
    {
        path: "/attendance",
        element: <AttendancePage />
    }
]

const plainRoutes: RouteObject[] = [
    {
        path: "/membership",
        element: <Navigate to="/account/membership" replace />
    },
    {
        path: "/onboarding",
        element: <OnboardingPage />
    },
    {
        path: "/attendance/scan",
        element: <AttendanceSelfServiceScannerPage />
    },
    {
        path: "/admin/projects/:projectId/:projectSessionId/attendance/liveqr",
        element: <AdminProjectAttendanceLiveQrPage />
    },
    {
        path: "/admin/projects/:projectId/:projectSessionId/attendance/adminscan",
        element: <AdminProjectAttendanceAdminscannerPage />
    },
    {
        path: "/acp-gbm-10-3-2024",
        element: <Temp_GBM_10_3_AdminScannerPage />
    }
]

const handlers: RouteObject[] = [
    {
        path: "/login",
        loader: LoginHandler
    },
    {
        path: "/loginsuccess",
        loader: LoginSuccessHandler
    },
    {
        path: "/confirmemail/:code",
        loader: ConfirmEmailHandler
    },
    {
        path: "/purchasemembership",
        loader: PurchaseMembershipHandler
    },
    {
        path: "/ecwidsso",
        loader: EcwidSsoHandler
    },
    {
        path: "/logout",
        async loader() { window.location.assign("/api/logout"); return null; }
    },
    {
        path: "/logoutsuccess",
        element: <Navigate to="/" replace />
    }
]

export const router = createBrowserRouter([
    {
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            {
                element: <WithTopbarLayout />,
                children: withTopbarRoutes
            },
            ...plainRoutes,
            ...handlers
        ]
    },
])

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)

root.render(
    <React.StrictMode>
        <UrqlProvider value={urqlClient}>
            <RouterProvider router={router} future={{ v7_startTransition: true }} />

            <ToastContainer
                position="bottom-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={true}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </UrqlProvider>
    </React.StrictMode>
)